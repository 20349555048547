import React from "react"
import PropTypes from "prop-types"

const Duplet = ({h, title, text, center, className, children, ...props}) => {

  const Tag = `h${h}`

  let classNames = ['hcc-duplet']

  if ( center.length > 0 ) {
    center.forEach(el => {
      classNames.push(`center-${el}`)
    })
  }

  if ( !! className ) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(' ')} {...props}>
      <Tag className="hcc-duplet-title">{title}</Tag>
      {text && <p className="hcc-duplet-text">{text}</p>}
      {children}
    </div>
  )

}

Duplet.defaultProps = {
  h         : '2',
  title     : null,
  text      : null,
  center    : [],
  className : '',
}

Duplet.propTypes = {
  h         : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title     : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  text      : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  center    : PropTypes.array,
  className : PropTypes.string,
}

export default Duplet
