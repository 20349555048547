import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Bg from "../components/Bg"
import Container from "../components/Container"
import Duplet from "../components/Duplet"
import Img from "../components/Img"
import Jumbotron from "../components/Jumbotron"
import Scroller from "../components/Scroller"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import Svg from "../components/Svg"
import { randomMinistrySlug } from "../helpers"
import "../css/about.scss"

const AboutPage = () => {
  const values = [
    { content : <React.Fragment>We love to help people <strong>find God</strong></React.Fragment> },
    { content : <React.Fragment>We relate in a <strong>real</strong> &amp; <strong>friendly</strong> way</React.Fragment> },
    { content : <React.Fragment>We teach God&apos;s Word <strong>boldly</strong> &amp; <strong>practically</strong></React.Fragment> },
    { content : <React.Fragment>We challenge people to <strong>follow Christ</strong> fully</React.Fragment> },
    { content : <React.Fragment>We <strong>serve</strong> our local &amp; global communities</React.Fragment> },
    { content : <React.Fragment>We strive to <strong>make disciples</strong> of God&apos;s Word</React.Fragment> },
  ]

  const beliefs = [
    { title : <React.Fragment>The<br/>Bible</React.Fragment>,                content : <React.Fragment>We believe that Scripture in its entirety originated with God and that it was given through the instrumentality of chosen&nbsp;men.</React.Fragment>                                                        },
    { title : <React.Fragment>God&apos;s<br/>Nature</React.Fragment>,        content : <React.Fragment>We believe that there is one true, holy God, eternally existing in three persons—Father, Son, and Holy&nbsp;Spirit.</React.Fragment>                                                                        },
    { title : <React.Fragment>Jesus<br/>Christ</React.Fragment>,             content : <React.Fragment>Jesus Christ is the eternal second Person of the Trinity who was united forever with a true human nature by a miraculous conception and virgin&nbsp;birth.</React.Fragment>                                 },
    { title : <React.Fragment>Human<br/>Destiny</React.Fragment>,            content : <React.Fragment>Death occurs for all mankind, with a resurrection of the body to a judgment determining the fate of each individual—either eternal separation from God or eternal communion with&nbsp;God.</React.Fragment> },
    { title : <React.Fragment>Salvation&apos;s<br/>Purpose</React.Fragment>, content : <React.Fragment>The central purpose of God&apos;s revelation in Scripture is to call all people into fellowship with&nbsp;Him.</React.Fragment>                                                                             },
    { title : <React.Fragment>The<br/>Holy Spirit</React.Fragment>,          content : <React.Fragment>The essential accompaniment of a genuine relationship with Jesus Christ is a life of holiness and obedience, attained by believers as they submit to the Holy&nbsp;Spirit.</React.Fragment>                 },
    { title : <React.Fragment>The<br/>Church</React.Fragment>,               content : <React.Fragment>The corollary of union with Jesus Christ is that all believers become members of His body, the&nbsp;Church.</React.Fragment>                                                                                },
    { title : <React.Fragment>Faith &amp;<br/>Practice</React.Fragment>,     content : <React.Fragment>The Holy Scripture is the final authority in all matters of faith and&nbsp;practice.</React.Fragment>                                                                                                       },
  ]

  return (
    <StaticQuery
      query={graphql`
        query AboutQuery {
          allDatoCmsPerson(sort: {fields: position, order: ASC}, filter: {isMeetYourStaff: {in: true}}) {
            edges {
              node {
                ...PersonFragment
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Site
            title     = "About"
            className = "hcc-about"
          >
            <article>
              <Section tag="header" className="hcc-about-intro">
                <Container size="hero" className="hcc-grid">
                  <Jumbotron content={<>We exist to help people discover who Jesus really is, and live with, like, and for Him, <strong>now and&nbsp;forever.</strong></>}/>
                  <Img className="is-01" alt="Collage Image" src="/about/intro-missions.jpg"/>
                  <Img className="is-02" alt="Collage Image" src="/about/intro-pete.jpg"/>
                  <Img className="is-03" alt="Collage Image" src="/about/intro-high-five.jpg"/>
                  <Img className="is-04" alt="Collage Image" src="/about/intro-worship-2.jpg"/>
                  <Img className="is-05" alt="Collage Image" src="/about/intro-hug.jpg"/>
                  <Img className="is-06" alt="Collage Image" src="/about/intro-laughter.jpg"/>
                  <Img className="is-07" alt="Collage Image" src="/about/intro-storytime.jpg"/>
                  <i className="hcc-about-intro-accent-bg" aria-hidden="true"></i>
                </Container>
              </Section>



              <Section className="hcc-about-staff has-scroller">
                <Container>
                  <h2><span>Meet</span> <span>your staff.</span></h2>
                </Container>
                <Scroller showButtons={true}>
                  <ul className="hcc-flex">
                    {data.allDatoCmsPerson.edges.map(({node : person}) => {
                      if ( person.isElder && person.slug !== "pete-chiofalo" ) {
                        return false
                      }

                      return (
                        <li key={person.id} style={{ '--c-base-accent' : `var(--c-${randomMinistrySlug()})` }}>
                          <Img src={person.headshot.url} alt={person.name}>
                            <Bg
                              layers={[
                                {
                                  stack : [
                                    { angle: 'to bottom', stops: ['transparent 57%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 60%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 63%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 66%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 69%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 72%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 75%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                  ]
                                }
                              ]}
                            />
                          </Img>
                          <Duplet
                            h     = "3"
                            title = {<a href={`mailto:${person.email}?subject=Howdy, ${person.name.split(' ').shift()}!`} aria-label={`Email ${person.name.split(' ').shift()} ${person.name.split(' ').pop()}`}><span><i>{person.name.split(' ').shift()}</i><br/><b>{person.name.split(' ').pop()}</b></span></a>}
                            text  = {person.staffPosition}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </Scroller>

                <Container>
                  <h2><span>Meet</span> <span>your elders.</span></h2>
                </Container>
                <Scroller showButtons={true}>
                  <ul className="hcc-flex">
                    {data.allDatoCmsPerson.edges.map(({node : person}) => {
                      if ( ! person.isElder || person.slug === "pete-chiofalo" ) {
                        return false
                      }

                      return (
                        <li key={person.id} style={{ '--c-base-accent' : `var(--c-${randomMinistrySlug()})` }}>
                          <Img src={person.headshot.url} alt={person.name}>
                            <Bg
                              layers={[
                                {
                                  stack : [
                                    { angle: 'to bottom', stops: ['transparent 57%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 60%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 63%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 66%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 69%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 72%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                    { angle: 'to bottom', stops: ['transparent 75%', 'rgba(15, 15, 15, 0.77) 100%'] },
                                  ]
                                }
                              ]}
                            />
                          </Img>
                          <Duplet
                            h     = "3"
                            title = {<a href={`mailto:${person.email}?subject=Howdy, ${person.name.split(' ').shift()}!`} aria-label={`Email ${person.name.split(' ').shift()} ${person.name.split(' ').pop()}`}><span><i>{person.name.split(' ').shift()}</i><br/><b>{person.name.split(' ').pop()}</b></span></a>}
                            text  = {person.staffPosition}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </Scroller>
              </Section>



              <Section className="hcc-about-values hcc-grid">
                <figure>
                  <Img src="/about/values.jpg" alt="Values"/>
                </figure>
                <div className="is-content">
                  <h2>What <span>we value&hellip;</span></h2>
                  <ul className="hcc-grid">
                    {values.map((value, i) => {
                      return (
                        <li key={`value-${i}`}>
                          <Svg type={`value-${i + 1}`}/>
                          <span>{value.content}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </Section>



              <Section className="hcc-about-beliefs has-scroller">
                <Container>
                  <h2>What <span>we believe&hellip;</span></h2>
                </Container>
                <Scroller showButtons={false}>
                  <ul className="hcc-flex">
                    {beliefs.map((el, i) => {
                      return (
                        <li key={`belief-${i}`}>
                          <p>
                            <strong>{el.title}</strong>
                            <span>{el.content}</span>
                          </p>
                        </li>
                      )
                    })}
                  </ul>
                </Scroller>
              </Section>



              <Section className="hcc-about-baptism">
                <svg
                  className           = "hcc-waves"
                  version             = "1.1"
                  xmlns               = "http://www.w3.org/2000/svg"
                  xmlnsXlink          = "http://www.w3.org/1999/xlink"
                  viewBox             = "0 0 1440 143.2"
                  xmlSpace            = "preserve"
                  preserveAspectRatio = "none"
                >
                  <path class="hcc-wave is-secondary" d="M0,0c144,21.1,144,21.1,288,10.6S432,0,576,31.1s144,31.1,288,8.8s144-22.3,288-12.3s144,10.1,288,10.4V140H0V0z"/>
                  <path class="hcc-wave is-primary" d="M0,18.2C240,33.8,240,33.8,480,26s240-7.8,480,19.9s240,27.7,480-11v108.3H0V18.2z"/>
                </svg>
                <div className="hcc-about-baptism-inner">
                  <Container>
                    <h2>On baptism.</h2>
                    <ul className="hcc-grid">
                      <li><span>We understand baptism to be only for those who profess Jesus Christ as Lord and Savior and that it is an act of obedience by those who subject themselves to His sovereignty.</span></li>
                      <li><span>We believe baptism symbolizes the spiritual cleansing and conversion of believers and is thus an essential part of one&apos;s faith but does not in and of itself save.</span></li>
                      <li><span>We baptize by immersion, and honor other modes of baptism (sprinkling, pouring, et cetera) as long as it occurred after personal belief in Christ and was understood by the one being baptized. Otherwise we re-baptize.</span></li>
                      <li><span>Children may be baptized, as long as they are old enough to understand and receive the gospel, are able to give testimony to others of their faith, and are given permission by their parents.</span></li>
                      <li><span>As a biblical rite of initiation into the body of Christ, baptism of believers is considered a prerequisite for joining the membership of the church.</span></li>
                      <li><span>Interested in more? If you&apos;re curious about your next steps towards baptism, <SiteLink href="/baptism/">click here</SiteLink> to learn about the process at Hillside.</span></li>
                    </ul>
                  </Container>
                </div>
              </Section>




            </article>
          </Site>
        )
      }}
    />
  )
}

export default AboutPage
