import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Button from "../components/Button"

const Scroller = ({tag, tagContent, showButtons, children, ...props}) => {

  // Constructors and Lifecycle
  // --------------------------
  // 01. The first argument is fired after browser layout and paint. The
  //     second argument is an array of values, which when empty only fires
  //     once, making it similar to the componentDidMount() method.
  // 02. When you return a function in the callback of useEffect(), the
  //     returned function will be called before the component is removed,
  //     making it similar to the componentWillUnmount() method.
  // 03. https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook#answer-55854902

  const Tag        = tag
  const TagContent = tagContent
  const elScroll   = useRef(null)
  const elContent  = useRef(null)

  let [scrollerWidthFull, setScrollerWidthFull] = useState(0)
  let [scrollerWidthAdj,  setScrollerWidthAdj]  = useState(0)
  let [contentWidth,      setContentWidth]      = useState(0)
  let [scrollOffset,      setScrollOffset]      = useState(0)

  useEffect(() => { // 01
    if ( showButtons ) {
      setTimeout(() => {
        setDimensions()
      }, 0)
      window.addEventListener('resize', setDimensions)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => { // 02
    return () => {
      if ( showButtons ) {
        window.removeEventListener('resize', setDimensions)
      }
    }
    // eslint-disable-next-line
  }, [])


  // Getters and Setters
  // -------------------

  let getScrollerWidthFull = () => { return scrollerWidthFull }
  let getScrollerWidthAdj  = () => { return scrollerWidthAdj  }
  let getContentWidth      = () => { return contentWidth      }
  let getScrollOffset      = () => { return scrollOffset      }

  let setDimensions = () => {
    if ( ! showButtons ) {
      return
    }

    if ( ! elScroll || ! elContent ) {
      return
    }

    let cssElScrollWidth       = parseInt(window.getComputedStyle(elScroll.current).width)
    let cssElScrollPseudoWidth = parseInt(window.getComputedStyle(elScroll.current, ':before').width)
    let cssElContentWidth      = parseInt(window.getComputedStyle(elContent.current).width)

    if ( isNaN(cssElScrollWidth) )       { cssElScrollWidth       = 0 }
    if ( isNaN(cssElScrollPseudoWidth) ) { cssElScrollPseudoWidth = 0 }
    if ( isNaN(cssElContentWidth) )      { cssElContentWidth      = 0 }

    setScrollerWidthFull(cssElScrollWidth)
    setScrollerWidthAdj(cssElScrollWidth - (cssElScrollPseudoWidth * 2))
    setContentWidth(cssElContentWidth)
    setScrollOffset(elScroll.current.scrollLeft)
  }


  // Positioning
  // -----------

  let atStart = () => { return getScrollOffset() <= 0                                               }
  let atEnd   = () => { return (getContentWidth() - getScrollOffset()) - getScrollerWidthAdj() <= 0 }


  // Navigation
  // ----------

  let goBack = () => {
    let scrollAmt = getScrollOffset() - getScrollerWidthFull()
    let floor     = 0
    elScroll.current.scrollTo({top : 0, left : Math.max(scrollAmt, floor), behavior : 'smooth'})
  }

  let goForward = () => {
    let scrollAmt = getScrollOffset() + getScrollerWidthFull()
    let ceil      = getContentWidth() - getScrollerWidthAdj()
    elScroll.current.scrollTo({top : 0, left : Math.min(scrollAmt, ceil), behavior : 'smooth'})
  }


  // classNames
  // ----------

  let renderButtonClassNames = (type = 'bck') => {
    let theClassNames = [`is-${type}`]
    let bckAtStart    = type === 'bck' && atStart()
    let fwdAtEnd      = type === 'fwd' && atEnd()

    if ( bckAtStart || fwdAtEnd ) {
      theClassNames.push('is-hidden')
    }

    return theClassNames.join(' ')
  }


  // Output
  // ------

  return (
    <Tag className="hcc-scroller" onScroll={setDimensions} {...props}>
      <div className="hcc-scroller-outer">
        <div className="hcc-scroller-inner" ref={elScroll}>
          <TagContent className="hcc-scroller-content" ref={elContent}>
            {children}
          </TagContent>
        </div>
      </div>
      {showButtons &&
        <nav className="hcc-scroller-nav">
          <Button className={renderButtonClassNames('bck')} label="&larr;" onClick={() => goBack()}/>
          <Button className={renderButtonClassNames('fwd')} label="&rarr;" onClick={() => goForward()}/>
        </nav>
      }
    </Tag>
  )
}

Scroller.defaultProps = {
  tag         : 'div',
  tagContent  : 'div',
  showButtons : true,
  children    : null,
}

Scroller.propTypes = {
  tag         : PropTypes.string,
  tagContent  : PropTypes.string,
  showButtons : PropTypes.bool,
  children    : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Scroller
